import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import moment from 'moment/moment'
import { mapGetters, mapState } from 'vuex'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import SettingApplicator from '@/modules/common/components/ui-core/setting-applicator/setting-applicator.vue'
import SettingChips from '@/modules/common/components/ui-core/setting-chips/setting-chips.vue'

@Component({
  name: 'SettingsFields',
  computed: {
    ...mapState('setting', ['providerFields']),
    ...mapGetters('option', ['registrationTypes'])
  },
  components: {
    'setting-applicator': SettingApplicator,
    'setting-chips': SettingChips
  }
})
export default class SettingsFields extends Vue {
  providerFields!: Array<any>

  @Prop({ required: true })
  value!: Record<string, any>

  @Prop({ required: false, type: Array, default: () => [] })
  readonly defaultOnlySettings!: Array<string>

  @Prop({ required: false, type: Array, default: () => [] })
  readonly fieldsPrint!: Array<unknown>

  @Prop({ required: true, type: String, default: '' })
  readonly permissions!: string

  @Prop({ required: true, type: String, default: '' })
  readonly eventUuid!: string

  @Prop({ required: false, type: Array, default: () => [] })
  readonly displayTabs!: Array<string>

  // TODO(zb): This is to handle a special case for track settings. Review this later.
  @Prop({ required: false, type: Array, default: () => [] })
  sessionExportFields!: Array<Record<string, any>>

  @Prop({ required: false, type: Array, default: () => [] })
  sessionFields!: Array<any>

  @Watch('settingsObject', { deep: true })
  onSettingsObjectChange (payload) {
    this.settingsObject = payload
  }

  registrationTypes!: Array<any> // vuex binding

  tab = ''
  languageToUse = 'en'
  registrationTypeKey = '_default'
  operatorFunctions = {
    '=': (a, b) => a === b,
    '!=': (a, b) => a !== b,
    '>': (a, b) => parseInt(a) > parseInt(b),
    '<': (a, b) => parseInt(a) < parseInt(b),
    '>=': (a, b) => parseInt(a) >= parseInt(b),
    '<=': (a, b) => parseInt(a) <= parseInt(b),
    '^=': (a, b) => a.includes(b)
  }

  valueType = [
    { text: 'Field', value: 'field' },
    { text: 'Literal', value: 'literal' }
  ]

  get pages () {
    if (this.displayTabs.length > 0) {
      return Object.keys(this.value).filter(page => this.displayTabs.includes(page.toLowerCase()))
    } else {
      return Object.keys(this.value)
    }
  }

  get settingsObject () {
    return this.value
  }

  set settingsObject (value) {
    this.$emit('input', value)
  }

  get commonVBind () {
    return {
      dense: true,
      'hide-details': true,
      outlined: true,
      disabled: !this.$hasPermission(this.permissions)
    }
  }

  mounted () {
    this.tab = this.pages[0]
  }

  resetValue (setting, registration_type) {
    setting[registration_type] = null
  }

  getItemsOfFontByTemplate (template = 'default'): Array<{ value: string; text: string }> {
    const fonts: any[] = []
    const designFields = this.$store.state.design.designFields
    if (designFields) {
      if (designFields[template]) {
        Object.keys(designFields[template]).map((key: any) => {
          if (key.split('_')[0] === template && designFields[template][key].type === 'font') {
            Object.keys(designFields[template][key].options).map((option: any) => {
              const font: any = {
                value: option,
                text: designFields[template][key].options[option]
              }
              if (!fonts.includes(font)) {
                fonts.push(font)
              }
            })
          }
        })
      }
    }
    return fonts
  }

  getSettingName (setting, key) {
    if ('display_name' in setting && setting.display_name) {
      return setting.display_name
    } else if ('name' in setting && setting.name) {
      return setting.name
    }
    return key
  }

  getShowCondition (setting) {
    if ('show_field' in setting) {
      return setting.show_field
    }
    return true
  }

  async loadFields (provider, table) {
    this.$store.dispatch('setting/getProviderFields', { event_uuid: this.eventUuid, provider, table })
  }

  showSettingDefault ({ default: settingDefault, type }) {
    return ![null, undefined, ''].includes(settingDefault) && !['fields', 'fieldswithwidth', 'fieldmapping', 'localized', 'localized_html'].includes(type)
  }

  registrationTypesToDisplay (setting) {
    if (this.defaultOnlySettings.includes(setting)) {
      return this.registrationTypes.filter(registration_type => registration_type.value === '_default')
    }
    return this.registrationTypes
  }

  regTypeName (value: string) {
    const _value: any = this.registrationTypes.filter((reg_type: any) => reg_type.value === value)
    if (_value.length) {
      return this.capitalizeText(_value[0].text)
    }
  }

  capitalizeText (value: string) {
    return value.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
  }

  async reUseValue (setting: any, value: any) {
    if (setting.type === 'localized_html' || setting.type === 'localized') {
      setting.temp = value[this.languageToUse]
    } else if (setting.type === 'fields' || setting.type === 'fieldmapping') {
      setting.temp_field = value.field
      setting.temp_label = value.label[this.languageToUse]
    } else if (setting.type === 'url') {
      setting.temp = await this.convertUrlToFile(value)
    } else {
      setting.temp = value
    }
  }

  removeField (setting: any, reg_type: any, field: any, tab: any) {
    // Objects are shallow copies, so just modifying the passed setting will work here.
    setting.value[reg_type] = setting.value[reg_type].filter(value => {
      if (value.field !== field.field) {
        return true
      }
    })
  }

  private async convertUrlToFile (url: string) {
    const response = await fetch(url)
    const blob = await response.blob()
    const fileExtension = url.split('.').pop()
    const file = new File([blob], 'image.' + fileExtension, { type: blob.type })
    return file
  }

  parseSetting (value: any) {
    return value.split(' ').join('_').toLowerCase()
  }

  setImageBase64 (file: File, reg_type_value: any, setting: any, tab: any) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.settingsObject[tab][setting.name].value[reg_type_value] = reader.result
    }
  }

  convertSettingValuesToItemsArray (obj) {
    const arr: any = []
    for (const key in obj) {
      arr.push({
        text: obj[key],
        value: key
      })
    }
    return arr
  }

  getRegTypeValues (values: any) {
    const regValues: any = {}
    for (const value in values) {
      if (values[value] && typeof values[value] !== 'object' && !(values[value] instanceof File)) {
        regValues[value] = values[value]
      }
    }
    return regValues
  }

  formatValue (value: any) {
    if (value instanceof Date) {
      return moment(value).format('YYYY-MM-DD HH:mm')
    } else {
      return value
    }
  }

  setRegTypeValue (setting: any, reg_type: any, tab: any) {
    if (setting.type === 'localized' || setting.type === 'localized_html') {
      if (setting.temp !== null) {
        const values = setting.value[reg_type.value]
        setting.value[reg_type.value] = {
          [this.languageToUse]: setting.temp
        }
      }
    } else if (setting.type === 'fields') {
      if (setting.temp_field && setting.temp_label) {
        const fieldType = (this.fieldsPrint as Record<string, any>).find((field: any) => field.value === setting.temp_field)?.type ?? 'field'
        setting.error = ''
        const reg_type_values = setting.value[reg_type.value]
        const temp: any = []
        reg_type_values.forEach(value => {
          if (value.field !== setting.temp_field) {
            temp.push(value)
          }
        })
        temp.push({ type: fieldType, field: setting.temp_field, label: { en: setting.temp_label } })
        setting.value[reg_type.value] = temp
      } else {
        setting.error = 'Both field and label are required.'
      }
    } else if (setting.type === 'fieldswithwidth') {
      if (setting.temp_field && setting.temp_label && setting.temp_width) {
        setting.error = ''
        const reg_type_values = setting.value[reg_type.value]
        const temp: any = []
        reg_type_values.forEach(value => {
          if (value.field !== setting.temp_field) {
            temp.push(value)
          }
        })
        temp.push({
          type: 'field',
          field: setting.temp_field,
          label: { en: setting.temp_label },
          width: parseInt(setting.temp_width)
        })
        this.settingsObject[tab][setting.name].value[reg_type.value] = temp
      }
    } else if (setting.type === 'fieldmapping') {
      if (setting.temp_field && setting.temp_external_field && setting.temp_value_type) {
        const reg_type_values = setting.value[reg_type.value]
        const temp: any = []
        reg_type_values.forEach(value => {
          if (value.field !== setting.temp_field) {
            temp.push(value)
          }
        })
        temp.push({ external_field: setting.temp_external_field, field: setting.temp_field, valueType: setting.temp_value_type })
        setting.value[reg_type.value] = temp
      }
    } else if (setting.type === 'url') {
      if (setting.temp !== null) {
        setting.value[this.parseSetting(reg_type.text) + '_temp_file'] = setting.temp
        this.setImageBase64(setting.temp, reg_type.value, setting, tab)
      }
    } else if (setting.type === 'integer') {
      if (setting.temp !== null) {
        // TODO: clean this up. This function is hard to read as it is.
        // attempt to parse setting.temp to an int.
        const value = parseInt(setting.temp)
        // if parseInt is NaN show a notification.
        if (isNaN(value)) {
          Container.get(Notification).error(`Please enter a valid number in the ${setting}`)
        } else {
          // Then assign to the this.settingsObject[tab][setting.name].value[reg_type.value] to that value.
          setting.value[reg_type.value] = value
        }
      }
    } else {
      if (setting.temp !== null && setting.type !== 'localized_html') {
        setting.value[reg_type.value] = setting.temp
      }
    }
  }

  registrationTypesValuesToDisplay (setting, valueObject) {
    if (this.defaultOnlySettings.includes(setting)) {
      for (const registration_type in valueObject) {
        if (registration_type !== '_default') {
          delete valueObject[registration_type]
        }
      }
    }
    return valueObject
  }

  showOrHideSettingsFields () {
    const settings_with_show_if: any = []
    for (const setting in this.settingsObject) {
      if (this.settingsObject[setting].hasOwnProperty('show_if')) {
        settings_with_show_if.push(this.settingsObject[setting])
      }
    }
    for (let i = 0; i < settings_with_show_if.length; i++) {
      const setting_name = settings_with_show_if[i].name
      const setting_show_if = settings_with_show_if[i].show_if
      /**
       * Get the field and the current value of the setting
       */
      const show_if_field = setting_show_if.field
      const show_if_operator = setting_show_if.operator
      const show_if_value = setting_show_if.value

      if (
        this.settingsObject[show_if_field].type === 'localized' ||
        this.settingsObject[show_if_field].type === 'localized_html'
      ) {
        if (
          this.operatorFunctions[show_if_operator](
            this.settingsObject[show_if_field].value[this.registrationTypeKey][this.languageToUse],
            show_if_value
          )
        ) {
          this.settingsObject[setting_name].show_field = true
        } else {
          this.settingsObject[setting_name].show_field = false
        }
      } else {
        if (
          this.operatorFunctions[show_if_operator](
            this.settingsObject[show_if_field].value[this.registrationTypeKey],
            show_if_value
          )
        ) {
          this.settingsObject[setting_name].show_field = true
        } else {
          this.settingsObject[setting_name].show_field = false
        }
      }
    }
  }
}
