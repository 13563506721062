import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Container from 'typedi'
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'GtrNotice',
  computed: {
    ...mapState('common', ['noticeDisplayed', 'noticeData'])
  }
})
export default class GtrNotice extends Vue {
  content: string | null = null;
  url: string | null = null;
  noticeDisplayed!: boolean;
  noticeData!: Record<string, any>;

  async mounted () {
    try {
      await this.getSystemAlert()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async getSystemAlert (): Promise<void> {
    await this.$store.dispatch('common/getSystemAlert')
  }

  private async handleClose (): Promise<void> {
    await this.$store.dispatch('common/closeNotice')
  }

  get active (): boolean {
    return this.noticeDisplayed ?? false
  }

  get messageStyles () {
    return {
      width: this.noticeData.url1 ? '500px' : 'auto'
    }
  }
}
