import { Component, Prop, Watch } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { mapState } from 'vuex'
import { v4 } from 'uuid'
import IGtrShowIf from '@/interfaces/gtr-show-if.interface'

@Component({
  name: 'GtrShowIf',
  computed: {
    ...mapState('option', ['option_groups']),
    ...mapState('formbuilder', ['eventFields']),
    ...mapState('sessions', ['sessions'])
  }
})

export default class GtrShowIf extends GtrSuper {
  model: any
  groupOperatorItems: any
  typeItems: any
  statusItems: any
  loading = true

  @Prop()
  modelOverride: any

  @Prop()
  fieldItemsOverride: any

  @Prop()
  optionItemsOverride: any

  @Prop({ default: false })
  hideShowAllOption: any

  @Prop({ default: false })
  hideDeleteEntireShowIfButton: any

  @Prop({ type: Boolean, default: false })
  omitSessions!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly!: boolean;

  @Prop({ type: Boolean, default: true })
  required!: boolean;

  data () {
    return {
      loading: true,
      model: {
        visible: false,
        global_type: '',
        type: '',
        field: '',
        operator: '',
        value: '',
        group_operator: '',
        group_items: []
      },
      typeItems: [
        {
          text: 'Field',
          value: 'field_criteria'
        },
        {
          text: 'Option',
          value: 'option_criteria'
        },
        {
          text: 'Session',
          value: 'session_criteria'
        }
      ],
      singleShowIfOperatorItems: [
        {
          text: 'Equals',
          value: '='
        },
        {
          text: 'Does Not Equal',
          value: '!='
        },
        {
          text: 'Greater Than',
          value: '>'
        },
        {
          text: 'Less Than',
          value: '<'
        },
        {
          text: 'Greater Than Or Equal To',
          value: '>='
        },
        {
          text: 'Less Than Or Equal To',
          value: '<='
        },
        {
          text: 'Contains',
          value: '^='
        },
        {
          text: 'Does Not Contain',
          value: '!^='
        },
        {
          text: 'Is Null',
          value: '=null'
        },
        {
          text: 'Is Not Null',
          value: '!=null'
        },
        {
          text: 'Has Attended',
          value: 'attended'
        },
        {
          text: 'Has Not Attended',
          value: 'not_attended'
        }
      ],
      groupOperatorItems: [
        {
          text: 'All groups must be true',
          value: 'AND'
        },
        {
          text: 'At least one group must be true',
          value: 'OR'
        }
      ],
      conditionOperatorItems: [
        {
          text: 'All conditions must be true',
          value: 'AND'
        },
        {
          text: 'At least one condition must be true',
          value: 'OR'
        }
      ],
      selectFieldsOptions: {
        status: ['Complete', 'Incomplete', 'Waitlisted', 'Pending', 'Canceled', 'Declined', 'Approved', 'Transferred/Pending', 'Transferred', 'Deleted', 'Invited'],
        lr_status: ['Complete', 'Incomplete', 'Waitlisted', 'Pending', 'Canceled', 'Declined', 'Approved', 'Transferred/Pending', 'Transferred', 'Deleted', 'INACTIVE', 'Invited'],
        event_attendance_status: ['Attended', 'Not Attended'],
        payment_status: ['Paid', 'Owes', 'Refund Needed'],
        lr_payment_status: ['Paid', 'Owes', 'Refund Needed'],
        payment_status_group: ['Paid', 'Owes', 'Refund Needed'],
        badge_print_status: ['', 'PRINTED'],
        has_started_filling_surveys: ['0', '1'],
        has_surveys_to_fill: ['0', '1']
      },
      excludedFields: [
        'Active',
        'Additional User Line Items',
        'Auto Assign',
        'Auto Create Checkout Time',
        'Deleted At',
        'Deleted By',
        'Deletion Reason',
        'Description',
        'Comped',
        'Comped Secret',
        'Extra Bcc',
        'Flag',
        'Flag2',
        'Language',
        'Onsite Device',
        'Onsite Device Status',
        'Onsite Notes',
        'Password',
        'Password Reset Token',
        'Profile Photo',
        'Scan Modes',
        'Scantrakk Code Id'
      ],
      modelOverrideFirstRun: true
    }
  }

  async created () {
    this.$data.loading = true
    const promises = [this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid }), this.$store.dispatch('formbuilder/getEventFields', { event_uuid: this.$route.params.event_uuid }), this.$store.dispatch('sessions/fetchSessions', { event_uuid: this.$route.params.event_uuid })]
    await Promise.all(promises)
    this.$data.loading = false
  }

  resetSingleShowIfFields (context) {
    context.field = ''
    context.operator = ''
    context.value = ''
    context.type = ''
  }

  resetMultipleShowIfFields (context) {
    context.group_operator = ''
    context.group_items.splice(0)
  }

  singleShowIfOperatorItemsCalculated (showIfType: 'field_criteria' | 'option_criteria' | 'session_criteria') {
    if (showIfType === 'session_criteria') {
      return this.$data.singleShowIfOperatorItems.filter(item => ['attended', 'not_attended'].includes(item.value))
    }
    if (showIfType === 'option_criteria') {
      return this.$data.singleShowIfOperatorItems.filter(item => ['=', '!=', '=null', '!=null'].includes(item.value))
    }
    return this.$data.singleShowIfOperatorItems.filter(item => item.value !== 'attended' && item.value !== 'not_attended')
  }

  handleDeleteEntireShowIf () {
    this.resetSingleShowIfFields(this.$data.model)
    this.resetMultipleShowIfFields(this.$data.model)
    this.$data.model.global_type = ''
    this.$data.model.visible = false
  }

  get globalTypeItems () {
    const globalTypeItems = [
      { value: '*', text: 'Everyone' },
      { value: 'single', text: 'Single' },
      { value: 'multiple', text: 'Multiple' }
    ]
    if (this.hideShowAllOption) {
      return globalTypeItems.filter(item => item.value !== '*')
    }
    return globalTypeItems
  }

  get fieldItems () {
    let items: any[] = []
    if (this.fieldItemsOverride) {
      items = this.fieldItemsOverride
    } else if ((this as any).eventFields?.length) {
      items = (this as any).eventFields.filter(i => i.table === 'participants').map(field => {
        return {
          text: field.label || field.field,
          value: field.field
        }
      }).sort((a, b) => a.text.localeCompare(b.text))
    }
    items = items.filter(item => !this.$data.excludedFields.includes(item.text))
    return items
  }

  get showIfTypeLabel () {
    // if this.withSessions is true return "Field, Option, or Session" else return "Field or Option"
    return this.omitSessions ? 'Field or Option' : 'Field, Option, or Session'
  }

  get typeSelections () {
    if (this.omitSessions) {
      return this.typeItems.filter(item => item.value !== 'session_criteria')
    }
    return this.typeItems
  }

  get optionGroupOptionsMap () {
    if (!((this as any).option_groups || []).length) {
      return []
    }
    const items: any[] = []
    for (const group of (this as any).option_groups) {
      items[group.uuid] = group.options.map(option => {
        return {
          text: option.name,
          value: option.uuid
        }
      }).sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
    }
    return items
  }

  handleShowIfGlobalTypeChange ($event) {
    if ($event === 'single') {
      this.resetMultipleShowIfFields(this.$data.model)
      this.$data.model.type = ''
    } else if ($event === 'multiple') {
      this.resetSingleShowIfFields(this.$data.model)
      this.$data.model.type = 'grouping'
    } else {
      this.resetSingleShowIfFields(this.$data.model)
      this.resetMultipleShowIfFields(this.$data.model)
      this.$data.model.type = '*'
    }
  }

  handleShowIfSingleTypeChange (model: any) {
    model.field = ''
    model.operator = ''
    model.value = ''
  }

  get optionGroupItems () {
    if (this.optionItemsOverride) {
      return this.optionItemsOverride
    }
    return (this as any).option_groups.map(group => {
      return { value: group.uuid, text: group.name }
    }).sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
  }

  get sessionItems (): any[] {
    return (this as any).sessions.reduce((activeSessions: any, session: any) => {
      if (session.active) {
        activeSessions.push({ value: session.uuid, text: session.name })
      }
      return activeSessions
    }, []).sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
  }

  @Watch('modelOverride', { immediate: true })
  onModelOverrideChange (payload: IGtrShowIf) {
    if (payload && Object.keys(payload).length) {
      this.$data.model = payload
      if (payload?.global_type && this.$data.modelOverrideFirstRun) {
        this.$data.modelOverrideFirstRun = false
        if (payload.global_type === 'single' && this.isDatetimeField(payload.field)) {
          this.$data.model.value = new Date(Date.parse(payload.value))
        } else if (payload.global_type === 'multiple') {
          for (let i = 0; i < payload.group_items.length; i++) {
            for (let j = 0; j < payload.group_items[i].group_items.length; j++) {
              if (this.isDatetimeField(payload.group_items[i].group_items[j].field)) {
                payload.group_items[i].group_items[j].value = new Date(Date.parse(payload.group_items[i].group_items[j].value))
              }
            }
          }
        }
      }
    }
  }

  @Watch('model', { immediate: true })
  onModelChange (payload: IGtrShowIf) {
    if (payload) {
      this.$emit('show-if-change', payload)
    }
  }

  handleAddShowIfGroup () {
    this.$data.model.group_items.unshift({
      group_items: [],
      type: 'grouping',
      id: v4()
    })
  }

  handleAddGroupItem (group) {
    group.group_items.push({
      id: v4(),
      type: '',
      field: '',
      operator: '',
      value: ''
    })
  }

  handleDeleteChildGroup (parentGroup, childGroupIdToDelete) {
    parentGroup.group_items = parentGroup.group_items.filter((childGroup: any) => childGroup.id !== childGroupIdToDelete)
  }

  handleDeleteParentGroup (parentGroupIdToDelete) {
    this.$data.model.group_items = this.$data.model.group_items.filter((parentGroup: any) => parentGroup.id !== parentGroupIdToDelete)
    if (this.$data.model.group_items.length === 0) {
      this.resetMultipleShowIfFields(this.$data.model)
    }
  }

  handleClearGroupItemFieldAndValue (item) {
    if ('field' in item) {
      item.field = ''
    }
    if ('value' in item) {
      item.value = ''
    }
  }

  isDatetimeField (field: string): boolean {
    return ['badge_print_date', 'date_canceled', 'date_registered'].includes(field)
  }

  isSurveysField (field: string): boolean {
    return ['has_surveys_to_fill', 'has_started_filling_surveys'].includes(field)
  }
}
