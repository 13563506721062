import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { GtrEvent } from '@/interfaces/common.interface'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import { ValidationObserver, extend } from 'vee-validate'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import store from '@/bootstrap/store/store'

extend('uniqueEventId', {
  validate: async (value: string) => {
    try {
      const response = await store.dispatch('event/eventIdExists', value.replace(/[^0-9a-z-]/gi, '_'))
      return response.data.exists ? 'That event title has already been used.' : true
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
      return false
    }
  }
})

@Component({
  name: 'GtrEventCreationWizard'
})
export default class GtrCompanyEventsView extends Vue {
  @Prop({ required: true, type: Object })
  company!: Record<string, any>;

  @Prop({ required: true, type: Boolean })
  value!: boolean;

  @Ref()
  readonly step1!: InstanceType<typeof ValidationObserver>

  @Ref()
  readonly step2!: InstanceType<typeof ValidationObserver>

  @Ref()
  readonly step3!: InstanceType<typeof ValidationObserver>

  eventStep = 1;
  hideDetails = false;
  submitting = false;
  addressErrorMessage = false;

  newEvent: GtrEvent = {
    name: null,
    type: null,
    category: null,
    event_identifier: null,
    active_start_date: null,
    active_start_date_menu: false,
    active_end_date: null,
    active_end_date_menu: false,
    event_start_date: null,
    event_start_date_menu: false,
    event_end_date: null,
    event_end_date_menu: false,
    banner: null,
    favicon: null,
    address: null,
    timezone: null,
    latitude: null,
    longitude: null,
    address_data: null,
    company_uuid: null
  }

  eventTypeItems: string[] = [
    'Appearance or Signing',
    'Attraction',
    'Camp, Trip, or Retreat',
    'Class, Training, or Workshop',
    'Concert or Performance',
    'Conference',
    'Convention',
    'Dinner or Gala',
    'Festival or Fair',
    'Game or Competition',
    'Meeting or Networking Event',
    'Other',
    'Party or Social Gathering',
    'Race or Endurance Event',
    'Rally',
    'Screening',
    'Seminar or Talk',
    'Tour',
    'Tournament',
    'Tradeshow, Consumer Show, or Expo'
  ];

  eventCategoryItems: string[] = [
    'Auto, Boat & Air',
    'Business & Professional',
    'Charity & Causes',
    'Community & Culture',
    'Family & Education',
    'Fashion & Beauty',
    'Film, Media & Entertainment',
    'Food & Drink',
    'Government & Politics',
    'Health & Wellness',
    'Hobbies & Special Interest',
    'Home & Lifestyle',
    'Music',
    'Other',
    'Performing & Visual Arts',
    'Religion & Spirituality',
    'School Activities',
    'Science & Technology',
    'Seasonal & Holiday',
    'Sports & Fitness',
    'Travel & Outdoor'
  ];

  get eventCreateModal () {
    return this.value
  }

  async submit () {
    try {
      this.submitting = true
      if (this.newEvent.latitude && this.newEvent.longitude) {
        this.newEvent.latitude = this.newEvent.latitude.toString()
        this.newEvent.longitude = this.newEvent.longitude.toString()
      }
      let resetStep3 = false
      if (!(this.newEvent.banner instanceof File)) {
        this.newEvent.banner = null
        resetStep3 = true
      }
      if (!(this.newEvent.favicon instanceof File)) {
        this.newEvent.favicon = null
        resetStep3 = true
      }
      if (resetStep3) {
        const step3 = (this.$refs.step3 as any)
        step3.reset()
      }
      this.$store.dispatch('common/showLoader', { value: true })
      this.newEvent.company_uuid = this.$route.params.uuid
      this.eventStep = 4
      const eventData = await this.$store.dispatch('event/createEvent', this.$data.newEvent)
      const timer = ms => new Promise(resolve => setTimeout(resolve, ms))
      let isEventActive = eventData.status === 'ACTIVE'
      while (!isEventActive) {
        const result = await this.$store.dispatch('event/getEventDupe', { event_uuid: eventData.uuid })
        if (result.status === 'ACTIVE') {
          isEventActive = true
        } else if (result.status === 'FAILED_TO_CREATE') {
          Container.get(ErrorHandlerService).error('Event failed to create')
          return
        }
        await timer(3000)
      }
      Container.get(Notification).success('Event successfully created.')
      this.resetNewEvent()
      this.$emit('gtr-event-created')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.submitting = false
      this.$store.dispatch('common/hideLoader')
    }
  }

  finishEventModal () {
    this.$emit('input', false)
    this.eventStep = 1
    this.step1.reset()
    this.step2.reset()
    this.step3.reset()
    this.resetNewEvent()
  }

  bannerFilesChange (file: File): void {
    this.newEvent.banner = file
  }

  faviconFilesChange (file: File): void {
    this.newEvent.favicon = file
  }

  prefillEventIdentifier (val: string): void {
    if (val) {
      this.newEvent.event_identifier = val.replace(/[^0-9a-z-]/gi, '_')
    }
  }

  handleBlur (): void {
    if (this.newEvent.address) {
      this.hideDetails = true
      this.addressErrorMessage = false
    } else {
      this.hideDetails = false
      this.addressErrorMessage = true
    }
  }

  getAddressData (address_data: Record<string, any>): void {
    if (!address_data) return
    this.newEvent = {
      ...this.newEvent,
      address_data,
      latitude: address_data.latitude,
      longitude: address_data.longitude
    }
    this.handleTimeZone()
  }

  handleTimeZone (): void {
    const { address_data, address, latitude, longitude } = this.newEvent
    if (address_data !== null) {
      this.hideDetails = true
      this.$store.dispatch('event/loadTimezone', { latitude, longitude })
        .then((data) => {
          if (data) {
            this.newEvent.timezone = data.data.timeZoneId
          }
        })
        .catch((err) => Container.get(ErrorHandlerService).error(err))
    } else {
      if (address) {
        this.hideDetails = false
      }
    }
  }

  private resetNewEvent () {
    this.newEvent = {
      name: null,
      type: null,
      category: null,
      event_identifier: null,
      active_start_date: null,
      active_start_date_menu: false,
      active_end_date: null,
      active_end_date_menu: false,
      event_start_date: null,
      event_start_date_menu: false,
      event_end_date: null,
      event_end_date_menu: false,
      banner: null,
      favicon: null,
      address: null,
      timezone: null,
      latitude: null,
      longitude: null,
      address_data: null,
      company_uuid: null
    }
  }
}
