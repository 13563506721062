import IHttpClientDeleteParameters from '@/modules/common/contracts/http-client/http-client-delete-parameters.interface'
import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPatchParameters from '@/modules/common/contracts/http-client/http-client-patch-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import HttpClient from '@/modules/common/services/http-client.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Container, { Service } from 'typedi'
import { AxiosResponse } from 'axios'
import IHttpClientPutParameters from '@/modules/common/contracts/http-client/http-client-put-parameters.interface'

@Service()
export default class SessionsService {
  public async fetchSessions (event_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/sessions`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async fetchActivites (event_uuid: string, session_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/sessions/${session_uuid}/activities`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async fetchSessionsSimple (event_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/sessions-simple`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async fetchAttendanceCounts (event_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/sessions/attendance-counts`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSession (event_uuid: string, session_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/sessions/${session_uuid}`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async exportAllScansReport (event_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/sessions/attendance/export-all-scans`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async exportAllSessionScans (event_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/session-tracking/sessions/report-export-multiplesheets`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async exportSessionScans (event_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/sessions/attendance-counts/sessions/export`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async exportIndividualSessionScans (event_uuid: string, session_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/session-tracking/sessions/${session_uuid}/export?inout=true`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async exportParticipantAccessReport (event_uuid: string, participant_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/sessions/access/export`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async exportParticipantAttendanceReport (event_uuid: string, participant_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/sessions/attendance/export`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async exportUnregistredScans (event_uuid: string, session_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/session-tracking/sessions/${session_uuid}/notattended-export`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async createSession (event_uuid: string, data: any): Promise<AxiosResponse> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/sessions`,
      requireToken: true,
      payload: data
    }
    const response: AxiosResponse = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async createActivity (event_uuid: string, session_uuid: string, data: any): Promise<AxiosResponse> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/sessions/${session_uuid}/activities`,
      requireToken: true,
      payload: data
    }
    const response: AxiosResponse = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async updateActivity (event_uuid: string, session_uuid: string, data: any): Promise<AxiosResponse> {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/sessions/${session_uuid}/activities/${data.uuid}`,
      requireToken: true,
      payload: data
    }
    const response: AxiosResponse = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async getActivitiesForParticipant (event_uuid: string, participant_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/session-activities`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async deleteActivity (event_uuid: string, session_uuid: string, activity_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/sessions/${session_uuid}/activities/${activity_uuid}`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async overrideCap (event_uuid: string, participant_uuid: string, session_uuid: string, activity_uuid: string, cap: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/sessions/${session_uuid}/activities/${activity_uuid}/cap-override`,
      requireToken: true,
      payload: {
        cap,
        participant_uuid
      }
    }
    const response: AxiosResponse = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async fetchAllActivities (event_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/activities`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async updateSession (event_uuid: string, session_uuid: string, data: any): Promise<AxiosResponse> {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/sessions/${session_uuid}`,
      requireToken: true,
      payload: data
    }
    const response: AxiosResponse = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async deleteSession (event_uuid: string, session_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/sessions/${session_uuid}`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async getParticipantsAttendanceReport (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/surveys/attendance-report`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async deleteSessions (event_uuid: string, payload) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/sessions`,
      requireToken: true,
      config: {
        data: payload
      }
    }
    const response: AxiosResponse = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async getExportCourses (event_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/sessions/export`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSessionAttendance (event_uuid: string, session_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/sessions/${session_uuid}/attendance`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async giveAccess (participant_uuid: string, event_uuid: string, data: any): Promise<AxiosResponse> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/sessions/access`,
      requireToken: true,
      payload: data
    }
    const response: AxiosResponse = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async removeAccess (participant_uuid: string, event_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/sessions/access`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async getAccessForParticipant (event_uuid: string, participant_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/sessions/access`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  /**
   * Calls backend to grant access to a list of participants.
   * @param session_uuid
   * @param event_uuid
   * @param data
   * @returns {Promise<AxiosResponse>}
   */
  public async giveBulkAccess (session_uuid: string, event_uuid: string, data: any): Promise<AxiosResponse> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/sessions/${session_uuid}/access-bulk`,
      requireToken: true,
      payload: data
    }
    const response: AxiosResponse = await Container.get(HttpClient).post(httpParams)
    return response
  }

  /**
   * Calls backend to add scan for a list of participants.
   * @param session_uuid
   * @param event_uuid
   * @param data
   * @returns { Promise<AxiosResponse> }
   */
  public async addBulkAttendance (session_uuid: string, event_uuid: string, data: Record<string, any>): Promise<AxiosResponse> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/sessions/${session_uuid}/attendance-bulk`,
      requireToken: true,
      payload: data
    }
    const response: AxiosResponse = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async moveBulkAttendance (event_uuid: string, data: Record<string, any>): Promise<AxiosResponse> {
    const httpParams: IHttpClientPutParameters = {
      url: `/event/${event_uuid}/sessions/attendance/move`,
      requireToken: true,
      payload: data
    }
    const response: AxiosResponse = await Container.get(HttpClient).put(httpParams)
    return response
  }

  public async editScanTimeBulk (event_uuid: string, session_uuid: string, data: Record<string, any>): Promise<AxiosResponse> {
    const httpParams: IHttpClientPutParameters = {
      url: `/event/${event_uuid}/sessions/${session_uuid}/attendance/`,
      requireToken: true,
      payload: data
    }
    const response: AxiosResponse = await Container.get(HttpClient).put(httpParams)
    return response
  }

  /**
   * Calls backend to remove access to a list of participants.
   * @param session_uuid
   * @param event_uuid
   * @param data
   * @returns { Promise<AxiosResponse> }
   */
  public async removeBulkAttendance (session_uuid: string, event_uuid: string, data: Record<string, any>): Promise<AxiosResponse> {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/sessions/${session_uuid}/attendance-bulk`,
      requireToken: true,
      config: {
        data
      }
    }
    const response: AxiosResponse = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async removeBulkAccess (session_uuid: string, event_uuid: string, data: any) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/sessions/${session_uuid}/access-bulk`,
      requireToken: true,
      config: {
        data
      }
    }
    const response: AxiosResponse = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async getSessionsByParticipant (event_uuid: string, participant_uuid: string): Promise<AxiosResponse | undefined> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/sessions/attendance`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async addAttendance (event_uuid: string, participant_uuid: string, session_uuid: string, data: any): Promise<AxiosResponse | undefined> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/sessions/${session_uuid}/attendance`,
      requireToken: true,
      payload: data
    }
    const response: AxiosResponse = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getSessionTrackingData (event_uuid: string, session_uuid: string): Promise<AxiosResponse | undefined> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/session-tracking/sessions/${session_uuid}`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }
}
