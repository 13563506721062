import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import PaymentService from '../services/payment.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Module({
  namespaced: true
})
export default class PaymentStore extends VuexModule {
    payment_settings: any[] = []
    payment_processor: any = []
    payment_processor_widget: any = []
    payment_properties: any = {}
    stripe_token: any = {}
    stripe_checkout_token: any = {}
    setup = false

    @Mutation
    SET_PAYMENT_PROPERTIES (payload: any) {
      this.payment_properties = payload
    }

    @Mutation
    SET_STRIPE_TOKEN (payload: any) {
      this.stripe_token = payload
    }

    @Mutation
    SET_STRIPE_CHECKOUT_TOKEN (payload: any) {
      this.stripe_checkout_token = payload
    }

    @Mutation
    SET_PAYMENT_PROCESSOR (payload: any) {
      this.payment_processor = payload
    }

    @Mutation
    SET_PAYMENT_PROCESSOR_WIDGET (payload: any) {
      this.payment_processor_widget = payload
    }

    @Mutation
    SET_PAYMENT_SETTINGS (payload: any) {
      this.payment_settings = payload
    }

    @Mutation
    SET_SETUP (payload: boolean) {
      this.setup = payload
    }

    @Action({ rawError: true })
    async loadIntent () {
      const response = await Container.get(PaymentService).loadIntent()
      return response
    }

    @Action({ rawError: true })
    async getPaymentProperties (payload: any) {
      const { event_identifier, widget, participant_uuid, form } = payload
      const response = await Container.get(PaymentService).getPaymentProperties(event_identifier, widget, participant_uuid, form)
      this.context.commit('SET_PAYMENT_PROPERTIES', response.data)
      return response
    }

    @Action({ rawError: true })
    async getStripeToken (payload: any) {
      const { event_identifier, widget, participant_uuid, form } = payload
      try {
        const response = await Container.get(PaymentService).getStripeToken(event_identifier, widget, participant_uuid, form)
        this.context.commit('SET_STRIPE_TOKEN', response.data)
        return response
      } catch (err) {
        await Container.get(ErrorHandlerService).error(err)
      }
    }

    @Action({ rawError: true })
    async getStripeCheckoutToken (payload: any) {
      const { event_identifier, widget, participant_uuid, form, payment_details, line_items } = payload
      try {
        const response = await Container.get(PaymentService).getStripeCheckoutToken(event_identifier, widget, participant_uuid, form, payment_details, line_items)
        this.context.commit('SET_STRIPE_CHECKOUT_TOKEN', response.data)
        return response
      } catch (err) {
        await Container.get(ErrorHandlerService).error(err)
      }
    }

    @Action({ rawError: true })
    async getPaymentProcessor (payload: any) {
      const { event_uuid, form } = payload
      const response = await Container.get(PaymentService).getPaymentProcessor(event_uuid, form)
      if (response.data && typeof response.data === 'object' && response.data.processors) {
        const procs = Object.keys(response.data.processors)
        const widgets = Object.keys(response.data.widgets)
        this.context.commit('SET_PAYMENT_PROCESSOR', procs)
        this.context.commit('SET_PAYMENT_PROCESSOR_WIDGET', widgets)
      } else {
        this.context.commit('SET_PAYMENT_PROCESSOR', [])
        this.context.commit('SET_PAYMENT_PROCESSOR_WIDGET', [])
      }
      return response.data
    }

    @Action({ rawError: true })
    async getPaymentProcessorLR (payload: any) {
      const { event_uuid } = payload
      const response = await Container.get(PaymentService).getPaymentProcessorLR(event_uuid)
      if (response.data && typeof response.data === 'object' && response.data.processors) {
        const procs = Object.keys(response.data.processors)
        const widgets = Object.keys(response.data.widgets)
        this.context.commit('SET_PAYMENT_PROCESSOR', procs)
        this.context.commit('SET_PAYMENT_PROCESSOR_WIDGET', widgets)
      } else {
        this.context.commit('SET_PAYMENT_PROCESSOR', [])
        this.context.commit('SET_PAYMENT_PROCESSOR_WIDGET', [])
      }
      return response.data
    }

    @Action({ rawError: true })
    async postRefund (payload: {
      event_uuid: string;
      participant_uuid: string;
      data: Record<string, any>;
    }): Promise<any | undefined> {
      const { event_uuid, participant_uuid } = payload
      try {
        const response = await Container.get(PaymentService).postRefund(payload)
        await this.context.dispatch('registration/getRegistrationTransactions', { event_uuid, participant_uuid }, { root: true })
        await this.context.dispatch('registration/getRegistrationFees', { event_uuid, participant_uuid }, { root: true })
        return response
      } catch (error) {
        await Container.get(ErrorHandlerService).error(error)
      }
    }

    @Action({ rawError: true })
    async getPaymentSettings (payload: any) {
      const { event_uuid, widget, form } = payload
      const response = await Container.get(PaymentService).getPaymentSettings(event_uuid, widget, form)
      this.context.commit('SET_PAYMENT_SETTINGS', response.data)
      return response
    }

    @Action({ rawError: true })
    async getPaymentSettingsLR (payload: any) {
      const { event_uuid, widget } = payload
      const response = await Container.get(PaymentService).getPaymentSettingsLR(event_uuid, widget)
      this.context.commit('SET_PAYMENT_SETTINGS', response.data)
      return response
    }

    @Action({ rawError: true })
    async savePaymentSettings (payload: any) {
      const { event_uuid, widget, data } = payload
      const response = await Container.get(PaymentService).savePaymentSettings(event_uuid, widget, data)
      this.context.dispatch('getPaymentSettings', { event_uuid, widget })
      return response
    }

    @Action({ rawError: true })
    async savePaymentSettingsLR (payload: any) {
      const { event_uuid, widget, data } = payload
      const response = await Container.get(PaymentService).savePaymentSettingsLR(event_uuid, widget, data)
      this.context.dispatch('getPaymentSettingsLR', { event_uuid, widget })
      return response
    }

    @Action({ rawError: true })
    async savePaymentMethod (payload: any) {
      const response = await Container.get(PaymentService).savePaymentMethod(payload)
      return response
    }

    @Action({ rawError: true })
    async removePaymentMethod (payload: any) {
      const { id } = payload
      const response = await Container.get(PaymentService).removePaymentMethod(id)
      return response
    }

    @Action({ rawError: true })
    async createNonWidgetTransaction (payload: any) {
      const { event_uuid, participant_uuid, data } = payload
      const response = await Container.get(PaymentService).createNonWidgetTransaction(event_uuid, participant_uuid, data)
      return response
    }

    @Action({ rawError: true })
    async voidPaymentProcessorTransaction (payload: any) {
      const { event_uuid, participant_uuid, widget_id, data } = payload
      const response = await Container.get(PaymentService).voidPaymentProcessorTransaction(event_uuid, participant_uuid, widget_id, data)
      this.context.dispatch('registration/getRegistrationTransactions', { event_uuid, participant_uuid }, { root: true })
      this.context.dispatch('registration/getRegistrationFees', { event_uuid, participant_uuid }, { root: true })
      return response
    }

    @Action({ rawError: true })
    async voidNonWidgetTransaction (payload: any) {
      const { event_uuid, participant_uuid, data } = payload
      const response = await Container.get(PaymentService).voidNonWidgetTransaction(event_uuid, participant_uuid, data)
      this.context.dispatch('registration/getRegistrationTransactions', { event_uuid, participant_uuid }, { root: true })
      this.context.dispatch('registration/getRegistrationFees', { event_uuid, participant_uuid }, { root: true })
      return response
    }

    @Action({ rawError: true })
    async createStripeTransaction (payload: any) {
      const { event_uuid, widget_id, participant_uuid, payment_type, data } = payload
      const response = await Container.get(PaymentService).createStripeTransaction(event_uuid, widget_id, participant_uuid, payment_type, data)
      this.context.dispatch('registration/getRegistrationTransactions', { event_uuid, participant_uuid }, { root: true })
      this.context.dispatch('registration/getRegistrationFees', { event_uuid, participant_uuid }, { root: true })
      return response
    }

    @Action({ rawError: true })
    async createAuthnetTransaction (payload: any) {
      const { event_uuid, widget_id, participant_uuid, payment_type, data } = payload
      const response = await Container.get(PaymentService).createAuthnetTransaction(event_uuid, widget_id, participant_uuid, payment_type, data)
      this.context.dispatch('registration/getRegistrationTransactions', { event_uuid, participant_uuid }, { root: true })
      this.context.dispatch('registration/getRegistrationFees', { event_uuid, participant_uuid }, { root: true })
      return response
    }

    @Action({ rawError: true })
    async loadPaymentMethods () {
      const response = await Container.get(PaymentService).loadPaymentMethods()
      return response
    }

    @Action({ rawError: true })
    async setAsDefault (payload: any) {
      const { payment_id } = payload
      const response = await Container.get(PaymentService).setAsDefault(payment_id)
      return response
    }
}
