import { mapState } from 'vuex'
import { Component, Vue } from 'vue-property-decorator'
import 'reflect-metadata'
import '@/plugins'
import '@/bootstrap/global/filters'
import '@/bootstrap/global/loader-components'
import '@/bootstrap/validation/rules'
import '@/bootstrap/global/prototypes'
import '@/bootstrap/interceptors/response'
import '@/plugins/vuetify-google-autocomplete'
import '@/bootstrap/directives/role.directive'
import GrtSecurityLayout from '@/modules/common/views/layouts/security/security.layout.vue'
import GtrSecurityRegisterLayout from '@/modules/common/views/layouts/security/security-register.layout.vue'
import GtrAdminLayout from '@/modules/common/views/layouts/level-one/admin/admin.layout.vue'
import GtrGlobalSolutionLayout from '@/modules/common/views/layouts/level-two/global-solution/global-solution.layout.vue'
import GtrGlobalSolutionLayoutWithOutSideBar from '@/modules/common/views/layouts/level-two/global-solution-without-sidebar/global-solution-without-sidebar.layout.vue'
import GtrEventLayout from '@/modules/common/views/layouts/level-two/event/event.layout.vue'
import GtrModuleLayout from '@/modules/common/views/layouts/level-two/module/module.layout.vue'
import GtrNotice from '@/modules/common/components/ui-core/gtr-notice-component/gtr-notice.vue'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrStorage from '@/modules/common/services/storage.service'
import SecurityContext from '@/modules/common/services/security-context.service'

@Component({
  name: 'GtrApplication',
  computed: mapState('common', ['loader', 'noticeDisplayed']),
  components: {
    'security-layout': GrtSecurityLayout,
    'security-register-layout': GtrSecurityRegisterLayout,
    'admin-layout': GtrAdminLayout,
    'global-solution-layout': GtrGlobalSolutionLayout,
    'global-solution-without-sidebar-layout': GtrGlobalSolutionLayoutWithOutSideBar,
    'event-layout': GtrEventLayout,
    'module-layout': GtrModuleLayout,
    'gtr-notice': GtrNotice
  }
})
export default class GtrApplication extends Vue {
  refreshTokenInterval = 600000 // milliseconds

  data () {
    return {
      displayApiKeyWarning: false
    }
  }

  created () {
    this.refreshToken()
    setInterval(this.refreshToken, this.refreshTokenInterval)
    document.addEventListener('mouseup', function (event) {
      Container.get(GtrStorage).setItem('last_user_action', Date.now().toString())
    })
    this.$bus.$on('login', this.handleLogin)
    if (Container.get(GtrStorage).getItem('just_logged_in')) {
      this.handleLogin()
      Container.get(GtrStorage).removeItem('just_logged_in')
    }
  }

  get layout () {
    if (this.$route.meta && this.$route.meta.layout) {
      return `${this.$route.meta.layout}-layout`
    }
    return 'div'
  }

  /**
   * Refresh token if all the following are true:
   * - the token has not already been refreshed by any tab or window during the past interval
   * - the user is logged in
   * - the user was active on any tab or window during the last interval
   */
  async refreshToken () {
    let securityContext = Container.get(GtrStorage).getItem('security_context')
    if (securityContext) {
      const now = Date.now()
      const lastUserAction = Number(Container.get(GtrStorage).getItem('last_user_action'))
      if (now - lastUserAction <= this.refreshTokenInterval) {
        securityContext = JSON.parse(securityContext)
        try {
          await this.$store.dispatch('security/refreshToken', securityContext.expires_in)
        } catch (err) {
          Container.get(ErrorHandlerService).error(err)
        }
      }
    }
  }

  async handleLogin () {
    Container.get(GtrStorage).setItem('last_user_action', Date.now().toString())
    this.checkApiKey()
  }

  async checkApiKey () {
    try {
      // Hide this until we determine the best way to handle it.
      // const response = await this.$store.dispatch('security/getZapierKeys')
      // if (response.data?.some(key => key.expired)) {
      //   this.$data.displayApiKeyWarning = true
      // }
    } catch (err) {
      Container.get(ErrorHandlerService).error(err)
    }
  }

  hideApiKeyWarning () {
    this.$data.displayApiKeyWarning = false
  }

  redirectToProfile () {
    this.hideApiKeyWarning()
    const securityContext = JSON.parse(Container.get(SecurityContext).context())
    if (securityContext) {
      if (securityContext.user?.company?.uuid) {
        this.$router.push({
          name: 'level-two.profile',
          params: {
            uuid: securityContext.user.company.uuid
          }
        })
      } else {
        this.$router.push({
          name: 'level-one.profile'
        })
      }
    }
  }
}
