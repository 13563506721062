import SecurityContext from '@/modules/common/services/security-context.service'
import { Container } from 'typedi'

const hasPermission = (key: string[]|string): boolean => {
  const permissions: any [] = Container.get(SecurityContext).permissions()
  let allowed = true

  if (Array.isArray(key)) {
    for (let i = 0; i < key.length; i++) {
      if (!(permissions.hasOwnProperty(key[i]) && permissions[key[i]])) {
        allowed = false
        break
      }
    }
  } else {
    allowed = permissions.hasOwnProperty(key) && permissions[key]
  }

  return allowed
}

export default hasPermission
