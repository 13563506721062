import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { AxiosResponse } from 'axios'
import Container from 'typedi'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import MediaService from '../services/media.service'
import type { UpdateFontPackagePayload, CreateFontPackagePayload, DeleteFontPackagePayload } from '@/interfaces/common.interface'
@Module({
  namespaced: true
})
export default class MediaStore extends VuexModule {
  media: any[] = []

  // TODO: create a type.
  fonts: any[] = []

  get defaultFonts (): string[] {
    return [
      'Arvo',
      'Bitter',
      'Cormorant Garamond',
      'Crimson Pro',
      'Lato',
      'Libre Barcode 128',
      'Libre Barcode 128 Text',
      'Libre Barcode 39',
      'Libre Barcode 39 Text',
      'Libre Baskerville',
      'Lora',
      'Merriweather',
      'Merriweather Sans',
      'Montserrat',
      'Noto Serif',
      'Open Sans',
      'Oswald',
      'Outfit',
      'Playfair Display',
      'PT Sans',
      'PT Serif',
      'Raleway',
      'Roboto',
      'Roboto Condensed',
      'Slabo 27px'
    ]
  }

  get medias () {
    return this.media
  }

  get fontList () {
    return this.fonts
  }

  @Mutation
  SET_MEDIA (payload: any) {
    this.media = payload
  }

  @Mutation
  SET_FONTS (payload: any) {
    this.fonts = payload
  }

  @Action({ rawError: true })
  async getMedia (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(MediaService).getMedia(event_uuid)
    this.context.commit('SET_MEDIA', response.data)
    return response
  }

  @Action({ rawError: true })
  async uploadMedia (payload: any) {
    const { event_uuid, fileData, fetch, participant_uuid } = payload
    const response = await Container.get(MediaService).uploadMedia(event_uuid, fileData, participant_uuid)
    if (fetch) {
      this.context.dispatch('getMedia', payload)
    }
    return response
  }

  @Action({ rawError: true })
  async removeMedia (payload: any) {
    const { event_uuid, file_identifier, fetch } = payload
    const response = await Container.get(MediaService).deleteMedia(event_uuid, file_identifier)
    if (fetch) {
      this.context.dispatch('getMedia', payload)
    }
    return response
  }

  // TODO: create types for these payloads.
  @Action({ rawError: true })
  async getFontStores (companyUuid: string) {
    const response = await Container.get(MediaService).getCompanyFontStore(companyUuid)
    this.context.commit('SET_FONTS', response.data)
    return response
  }

  /**
   * calls the patch request from the media service and returns the response.
   * @param {any} the font package and font package uuid
   * @returns {AxiosResponse}
   */
  // @Action({ rawError: true })
  // async updateFontPackage ({ companyUuid, updatedFontPackage, fontPackageUuid }: UpdateFontPackagePayload) {
  //   try {
  //     const response = await Container.get(MediaService).updateFontInFontStore(companyUuid, updatedFontPackage, fontPackageUuid)
  //     this.context.dispatch('getFontStores', companyUuid)
  //     return response
  //   } catch (err) {
  //     Container.get(ErrorHandlerService).error(err)
  //   }
  // }

  @Action({ rawError: true })
  async createFontPackage ({ companyUuid, fontPackage }: CreateFontPackagePayload) {
    try {
      const response = await Container.get(MediaService).uploadFontToFontStore(companyUuid, fontPackage)
      this.context.dispatch('getFontStores', companyUuid)
      return response
    } catch (err) {
      Container.get(ErrorHandlerService).error(err)
    }
  }

  @Action({ rawError: true })
  async deleteFontPackage ({ fontPackageUuid, companyUuid }: DeleteFontPackagePayload) {
    const response = await Container.get(MediaService).deleteFontStore(fontPackageUuid, companyUuid)
    this.context.dispatch('getFontStores', companyUuid)
    return response
  }
}
