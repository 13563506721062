import Container from 'typedi'
import { Module, Mutation, Action, VuexModule } from 'vuex-module-decorators'
import { EmailTemplate } from '../models/email.model'
import EmailService from '../services/email.service'
import { GtrEmailTrigger } from '@/interfaces/common.interface'

@Module({
  namespaced: true
})
export default class EmailStore extends VuexModule {
  emails: any[] = []
  emailSettings: any[] = []
  emails_live: any[] = []
  scheduled_emails: any[] = []
  sent_scheduled_emails: any[] = []
  email: any = null
  emailPreview: any = {}
  changelog: any = {}
  currentlyDeployedLiveUUID: any = {}
  currentDevUUID: any = {}
  scheduledEmailParticipants: any = []
  emailTriggers: GtrEmailTrigger[] = []

  @Mutation
  ADD_EMAILS (payload: any) {
    this.emails.push(...payload)
  }

  @Mutation
  ADD_EMAILS_LIVE (payload: any) {
    this.emails_live.push(...payload)
  }

  @Mutation
  ADD_SCHEDULED_EMAILS (payload: any) {
    this.scheduled_emails.push(...payload)
  }

  @Mutation
  ADD_SENT_SCHEDULED_EMAILS (payload: any) {
    this.sent_scheduled_emails.push(...payload)
  }

  @Mutation
  ADD_TRIGGERS (payload: any) {
    this.emailTriggers.push(...payload)
  }

  @Mutation
  SET_EMAIL_SETTINGS (payload: any) {
    this.emailSettings = payload
  }

  @Mutation
  CLEAR_EMAILS () {
    this.emails = []
  }

  @Mutation
  CLEAR_EMAILS_LIVE () {
    this.emails_live = []
  }

  @Mutation
  CLEAR_SCHEDULED_EMAILS () {
    this.scheduled_emails = []
  }

  @Mutation
  CLEAR_SENT_SCHEDULED_EMAILS () {
    this.sent_scheduled_emails = []
  }

  @Mutation
  CLEAR_TRIGGERS () {
    this.emailTriggers = []
  }

  @Mutation
  SET_EMAIL_PREVIEW (payload: any) {
    this.emailPreview = payload
  }

  @Mutation
  SET_CHANGELOG (payload: any) {
    this.changelog = payload
  }

  @Mutation
  SET_CURRENT_DEPLOYED_LIVE_UUID (payload: any) {
    this.currentlyDeployedLiveUUID = payload
  }

  @Mutation
  SET_CURRENT_DEV_UUID (payload: any) {
    this.currentDevUUID = payload
  }

  @Mutation
  SET_SCHEDULED_EMAIL_PARTICIPANTS (payload: any) {
    this.scheduledEmailParticipants = payload.data
  }

  @Action({ rawError: true })
  async fetchEmails (payload: any) {
    const event_uuid = payload
    const environment = 'dev'
    const response: any = await Container.get(EmailService).getEmails(event_uuid, environment)
    const emails: any[] = []
    for (const [key, value] of Object.entries(response.data)) {
      emails.push({
        key,
        scheduled: false,
        value
      })
    }
    this.context.commit('CLEAR_EMAILS')
    this.context.commit('ADD_EMAILS', emails)
    return response
  }

  @Action({ rawError: true })
  async fetchEmailsLive (payload: any) {
    const event_uuid = payload
    const environment = 'live'
    const response: any = await Container.get(EmailService).getEmails(event_uuid, environment)
    const emails: any[] = []
    for (const [key, value] of Object.entries(response.data)) {
      emails.push({
        key,
        scheduled: false,
        value
      })
    }
    this.context.commit('CLEAR_EMAILS_LIVE')
    this.context.commit('ADD_EMAILS_LIVE', emails)
    return response
  }

  @Action({ rawError: true })
  async fetchScheduledEmails (payload: any) {
    const response: any = await Container.get(EmailService).getScheduledEmails(payload)
    const emails: any[] = []
    for (const [key, value] of Object.entries(response.data.data)) {
      emails.push({
        key,
        scheduled: true,
        value
      })
    }
    this.context.commit('CLEAR_SCHEDULED_EMAILS')
    this.context.commit('ADD_SCHEDULED_EMAILS', emails)
    return response
  }

  @Action({ rawError: true })
  async fetchSentScheduledEmails (payload: any) {
    const response: any = await Container.get(EmailService).getSentScheduledEmails(payload)
    const emails: any[] = []
    for (const [key, value] of Object.entries(response.data.data)) {
      emails.push({ key, value })
    }
    this.context.commit('CLEAR_SENT_SCHEDULED_EMAILS')
    this.context.commit('ADD_SENT_SCHEDULED_EMAILS', emails)
    return response
  }

  @Action({ rawError: true })
  async fetchScheduledEmailParticipants (payload: any) {
    const { event_uuid, scheduled_email_uuid } = payload
    const response: any = await Container.get(EmailService).getScheduledEmailParticipants(event_uuid, scheduled_email_uuid)
    this.context.commit('SET_SCHEDULED_EMAIL_PARTICIPANTS', response)
    return response
  }

  @Action({ rawError: true })
  async checkIfDNSCorrect (payload: any) {
    const { company_uuid, email } = payload
    const response: any = await Container.get(EmailService).checkIfVerifiedSender(company_uuid, email)
    return response.data
  }

  @Action({ rawError: true })
  async submitEmailToSendgrid (payload: any) {
    const { company_uuid, email } = payload
    const response: any = await Container.get(EmailService).submitEmailToSendgrid(company_uuid, email)
    return response
  }

  @Action({ rawError: true })
  async getDomains (payload: any) {
    const { company_uuid, email } = payload
    const response: any = await Container.get(EmailService).getDomains(company_uuid, email)
    return response.data
  }

  @Action({ rawError: true })
  async fetchEmail (payload: any) {
    return await Container.get(EmailService).getEmailByUuid(payload.event_uuid, payload.email_uuid)
  }

  @Action({ rawError: true })
  async createEmail (payload: EmailTemplate[]) {
    const response: any = await Container.get(EmailService).createEmail(payload)
    this.context.dispatch('fetchEmails', payload[0].event_uuid)
    const changelogData = { type: 'email', subtype: payload[0].email_data.name, event_uuid: payload[0].event_uuid }
    this.context.dispatch('getChangelog', changelogData)
    const deployData = { type: 'email', sub_type: payload[0].email_data.name, event_uuid: payload[0].event_uuid }
    this.context.dispatch('getCurrentDevUUID', deployData)
    return response
  }

  @Action({ rawError: true })
  async editEmail (payload: EmailTemplate[]) {
    const response: any = await Container.get(EmailService).updateEmail(payload)
    this.context.dispatch('fetchEmails', payload[0].event_uuid)
    const changelogData = { type: 'email', subtype: payload[0].email_data.name, event_uuid: payload[0].event_uuid }
    this.context.dispatch('getChangelog', changelogData)
    const deployData = { type: 'email', sub_type: payload[0].email_data.name, event_uuid: payload[0].event_uuid }
    this.context.dispatch('getCurrentDevUUID', deployData)
    return response
  }

  @Action({ rawError: true })
  async createScheduledEmail (payload: any) {
    const { event_uuid, data } = payload
    const response: any = await Container.get(EmailService).createScheduledEmail(event_uuid, data)
    this.context.dispatch('fetchScheduledEmails', event_uuid)
    return response
  }

  @Action({ rawError: true })
  async updateScheduledEmail (payload: any) {
    const { event_uuid, schedule_uuid, data } = payload
    const response: any = await Container.get(EmailService).updateScheduledEmail(event_uuid, schedule_uuid, data)
    this.context.dispatch('fetchScheduledEmails', event_uuid)
    return response
  }

  @Action({ rawError: true })
  async deleteEmail (payload: any) {
    const { event_uuid, filename } = payload
    const response = await Container.get(EmailService).deleteEmail(event_uuid, filename)
    this.context.dispatch('fetchEmails', event_uuid)
    return response
  }

  @Action({ rawError: true })
  async sendEmailToOneRegistration (payload: any) {
    const { event_uuid, company_uuid, registration_uuid, email_name, data } = payload
    const response = await Container.get(EmailService).sendEmailToOneRegistration(event_uuid, company_uuid, registration_uuid, email_name, data)
    return response
  }

  @Action({ rawError: true })
  async sendBulkEmail (payload: any) {
    const { event_uuid, participant_uuids, email_name } = payload
    return await Container.get(EmailService).sendBulkEmail(event_uuid, participant_uuids, email_name)
  }

  @Action({ rawError: true })
  async sendEmailToAdminUser (payload: any) {
    const { event_uuid, email_name, user_uuids } = payload
    return await Container.get(EmailService).sendEmailToAdminUser(event_uuid, email_name, user_uuids)
  }

  @Action({ rawError: true })
  async emailAllLeads (event_uuid: string) {
    return await Container.get(EmailService).emailAllLeads(event_uuid)
  }

  @Action({ rawError: true })
  async emailPreviewRegistration (payload: any) {
    const { event_uuid, registration_uuid, email_name } = payload
    const response = await Container.get(EmailService).emailPreviewRegistration(event_uuid, registration_uuid, email_name)
    this.context.commit('SET_EMAIL_PREVIEW', response.data)
    return response
  }

  @Action({ rawError: true })
  async getChangelog (payload: any) {
    const { event_uuid, type, subtype } = payload
    const response = await Container.get(EmailService).getChangelog(event_uuid, type, subtype)
    this.context.commit('SET_CHANGELOG', response.data)
    return response
  }

  @Action({ rawError: true })
  async getCurrentlyDeployedLiveUUID (payload: any) {
    const { event_uuid, type, sub_type } = payload
    if (event_uuid) {
      const response = await Container.get(EmailService).getUUID(event_uuid, type, sub_type)
      this.context.commit('SET_CURRENT_DEPLOYED_LIVE_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async getCurrentDevUUID (payload: any) {
    const { event_uuid, type, sub_type } = payload
    if (event_uuid) {
      const response = await Container.get(EmailService).getUUID(event_uuid, type, sub_type)
      this.context.commit('SET_CURRENT_DEV_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async removeScheduledEmail (payload: any) {
    const { event_uuid, scheduled_uuid } = payload
    const response = await Container.get(EmailService).deleteScheduledEmail(event_uuid, scheduled_uuid)
    return response
  }

  @Action({ rawError: true })
  async fetchEmailTriggers (event_uuid: string) {
    const response: any = await Container.get(EmailService).getEmailTriggers(event_uuid)
    const triggers: any[] = []
    for (const [key, value] of Object.entries(response.data)) {
      triggers.push(value)
    }
    this.context.commit('CLEAR_TRIGGERS')
    this.context.commit('ADD_TRIGGERS', triggers)
    return response
  }

  @Action({ rawError: true })
  async updateEmailTrigger (payload: any) {
    const { event_uuid, trigger_uuid, data } = payload
    const response: any = await Container.get(EmailService).updateEmailTrigger(event_uuid, trigger_uuid, data)
    this.context.dispatch('fetchEmailTriggers', event_uuid)
    return response
  }
}
