import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import SessionsService from '../services/sessions.service'

@Module({
  namespaced: true
})
export default class SessionsStore extends VuexModule {
  participant_access: any [] = []
  sessions: any [] = []
  activities: any [] = []
  all_activities: any [] = []
  activitiesByParticipant: any [] = []
  sessions_simple: any [] = []
  attendanceCounts: any [] = []
  sessionsByParticipant: any [] = []

  get totalSessions () {
    return this.sessions.length
  }

  @Mutation
  SET_SESSIONS (payload: any) {
    this.sessions = payload
  }

  @Mutation
  SET_ACTIVITIES (payload: any) {
    this.activities = payload
  }

  @Mutation
  SET_ALL_ACTIVITIES (payload: any) {
    this.all_activities = payload
  }

  @Mutation
  SET_ACTIVITIES_FOR_PARTICIPANT (payload: any) {
    this.activitiesByParticipant = payload
  }

  @Mutation
  SET_SESSIONS_SIMPLE (payload: any) {
    this.sessions_simple = payload
  }

  @Mutation
  SET_ACCESS_FOR_PARTICIPANT (payload: any) {
    this.participant_access = payload
  }

  @Mutation
  SET_ATTENDANCE_COUNTS (payload: any) {
    this.attendanceCounts = payload
  }

  @Mutation
  SET_SESSIONS_BY_PARTICIPANT (payload: any) {
    this.sessionsByParticipant = payload
  }

  @Action({ rawError: true })
  async fetchSessions (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(SessionsService).fetchSessions(event_uuid)
    this.context.commit('SET_SESSIONS', response.data.data)
    return response
  }

  @Action({ rawError: true })
  async fetchActivities (payload: any) {
    const { event_uuid, session_uuid } = payload
    const response = await Container.get(SessionsService).fetchActivites(event_uuid, session_uuid)
    this.context.commit('SET_ACTIVITIES', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async fetchSessionsSimple (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(SessionsService).fetchSessionsSimple(event_uuid)
    this.context.commit('SET_SESSIONS_SIMPLE', response.data)
    return response
  }

  @Action({ rawError: true })
  async fetchAllActivities (event_uuid: string) {
    const response = await Container.get(SessionsService).fetchAllActivities(event_uuid)
    this.context.commit('SET_ALL_ACTIVITIES', response.data)
    return response
  }

  @Action({ rawError: true })
  async fetchAttendanceCounts (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(SessionsService).fetchAttendanceCounts(event_uuid)
    this.context.commit('SET_ATTENDANCE_COUNTS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getSession (payload: any) {
    const { event_uuid, session_uuid } = payload
    const response = await Container.get(SessionsService).getSession(event_uuid, session_uuid)
    return response
  }

  @Action({ rawError: true })
  async createSession (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(SessionsService).createSession(event_uuid, data)
    await this.context.dispatch('fetchSessions', payload)
    return response
  }

  @Action({ rawError: true })
  async createActivity (payload: any) {
    const { event_uuid, session_uuid, data } = payload
    const response = await Container.get(SessionsService).createActivity(event_uuid, session_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async deleteActivity (payload: any) {
    const { event_uuid, session_uuid, activity_uuid } = payload
    const response = await Container.get(SessionsService).deleteActivity(event_uuid, session_uuid, activity_uuid)
    return response
  }

  @Action({ rawError: true })
  async updateActivity (payload: any) {
    const { event_uuid, session_uuid, data } = payload
    const response = await Container.get(SessionsService).updateActivity(event_uuid, session_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async updateSession (payload: any) {
    const { event_uuid, session_uuid, data } = payload
    const response = await Container.get(SessionsService).updateSession(event_uuid, session_uuid, data)
    await this.context.dispatch('fetchSessions', payload)
    return response
  }

  @Action({ rawError: true })
  async deleteSession (payload: any) {
    const { event_uuid, session_uuid } = payload
    const response = await Container.get(SessionsService).deleteSession(event_uuid, session_uuid)
    await this.context.dispatch('fetchSessions', payload)
    return response
  }

  @Action({ rawError: true })
  async fetchActivitiesForParticipant (payload: any) {
    const { event_uuid, participant_uuid } = payload
    const response = await Container.get(SessionsService).getActivitiesForParticipant(event_uuid, participant_uuid)
    this.context.commit('SET_ACTIVITIES_FOR_PARTICIPANT', response.data)
    return response
  }

  @Action({ rawError: true })
  async getAccessForParticipant (payload: any) {
    const { event_uuid, participant_uuid } = payload
    const response = await Container.get(SessionsService).getAccessForParticipant(event_uuid, participant_uuid)
    this.context.commit('SET_ACCESS_FOR_PARTICIPANT', response.data)
    return response
  }

  @Action({ rawError: true })
  async getParticipantsAttendanceReport (event_uuid: any) {
    const response = await Container.get(SessionsService).getParticipantsAttendanceReport(event_uuid)
    return response
  }

  @Action({ rawError: true })
  async deleteSessions (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(SessionsService).deleteSessions(event_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async getExportCourses (event_uuid: any) {
    const response = await Container.get(SessionsService).getExportCourses(event_uuid)
    return response
  }

  @Action({ rawError: true })
  async overrideCap (payload: any) {
    const { event_uuid, participant_uuid, session_uuid, activity_uuid, cap } = payload
    const response = await Container.get(SessionsService).overrideCap(event_uuid, participant_uuid, session_uuid, activity_uuid, cap)
    return response
  }

  @Action({ rawError: true })
  async exportAllScansReport (event_uuid: string) {
    return await Container.get(SessionsService).exportAllScansReport(event_uuid)
  }

  @Action({ rawError: true })
  async exportAllSessionScans (event_uuid: string) {
    return await Container.get(SessionsService).exportAllSessionScans(event_uuid)
  }

  @Action({ rawError: true })
  async exportParticipantAccessReport (payload: any) {
    const { event_uuid, participant_uuid } = payload
    return await Container.get(SessionsService).exportParticipantAccessReport(event_uuid, participant_uuid)
  }

  @Action({ rawError: true })
  async exportParticipantAttendanceReport (payload: any) {
    const { event_uuid, participant_uuid } = payload
    return await Container.get(SessionsService).exportParticipantAttendanceReport(event_uuid, participant_uuid)
  }

  @Action({ rawError: true })
  async exportSessionScans (event_uuid: any) {
    const response = await Container.get(SessionsService).exportSessionScans(event_uuid)
    return response
  }

  @Action({ rawError: true })
  async exportIndividualSessionScans (payload: any) {
    const { event_uuid, session_uuid } = payload
    return await Container.get(SessionsService).exportIndividualSessionScans(event_uuid, session_uuid)
  }

  @Action({ rawError: true })
  async exportUnregistredScans (payload: any) {
    const { event_uuid, session_uuid } = payload
    return await Container.get(SessionsService).exportUnregistredScans(event_uuid, session_uuid)
  }

  @Action({ rawError: true })
  async giveAccess (payload: any) {
    const { participant_uuid, event_uuid, data } = payload
    const response = await Container.get(SessionsService).giveAccess(participant_uuid, event_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async removeAccess (payload: any) {
    const { participant_uuid, data } = payload
    const response = await Container.get(SessionsService).removeAccess(participant_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async getSessionAttendance (payload: any) {
    const { event_uuid, session_uuid } = payload
    const response = await Container.get(SessionsService).getSessionAttendance(event_uuid, session_uuid)
    return response
  }

  @Action({ rawError: true })
  async giveBulkAccess (payload: any) {
    try {
      const { session_uuid, event_uuid, data } = payload
      const response = await Container.get(SessionsService).giveBulkAccess(session_uuid, event_uuid, data)
      return response
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  @Action({ rawError: true })
  async giveBulkAttendance (payload: any) {
    try {
      const { session_uuid, event_uuid, data } = payload
      const response = await Container.get(SessionsService).addBulkAttendance(session_uuid, event_uuid, data)
      return response
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  @Action({ rawError: true })
  async editScanTimeBulk (payload: any) {
    const { event_uuid, session_uuid, data } = payload
    return await Container.get(SessionsService).editScanTimeBulk(event_uuid, session_uuid, data)
  }

  @Action({ rawError: true })
  async moveBulkAttendance (payload: any) {
    try {
      const { event_uuid, data } = payload
      const response = await Container.get(SessionsService).moveBulkAttendance(event_uuid, data)
      return response
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  @Action({ rawError: true })
  async removeBulkAttendance (payload: any) {
    try {
      const { session_uuid, event_uuid, data } = payload
      const response = await Container.get(SessionsService).removeBulkAttendance(session_uuid, event_uuid, data)
      return response
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  @Action({ rawError: true })
  async removeBulkAccess (payload: any) {
    const { session_uuid, event_uuid, data } = payload
    const response = await Container.get(SessionsService).removeBulkAccess(session_uuid, event_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async getSessionsByParticipant (payload: any) {
    const { event_uuid, participant_uuid } = payload
    const response = await Container.get(SessionsService).getSessionsByParticipant(event_uuid, participant_uuid)
    // eslint-disable-next-line
    this.context.commit('SET_SESSIONS_BY_PARTICIPANT', response!.data)
    // eslint-disable-next-line
    return response!.data
  }

  @Action({ rawError: true })
  async addAttendance (payload: any) {
    const { event_uuid, participant_uuid, session_uuid, data } = payload
    await Container.get(SessionsService).addAttendance(event_uuid, participant_uuid, session_uuid, data)
  }

  @Action({ rawError: true })
  async getSessionTrackingData ({ event_uuid, session_uuid }: {
    event_uuid: string;
    session_uuid: string;
  }) {
    try {
      return await Container.get(SessionsService).getSessionTrackingData(event_uuid, session_uuid)
    } catch (err) {
      Container.get(Notification).error((err as Error).message)
    }
  }
}
