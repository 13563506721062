import { AxiosResponse } from 'axios'
import Container, { Service } from 'typedi'
import IHttpClientDeleteParameters from '../contracts/http-client/http-client-delete-parameters.interface'
import IHttpClientGetParameters from '../contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPatchParameters from '../contracts/http-client/http-client-patch-parameters.interface'
import IHttpClientPostParameters from '../contracts/http-client/http-client-post-parameters.interface'
import ApiCredentials from '../models/api-credentials.model'
import ChangePassword from '../models/change-password.model'
import Register from '../models/register.model'
import ResetPassword from '../models/reset-password.model'
import HttpClient from './http-client.service'
import SecurityContext from './security-context.service'

@Service()
export default class SecurityService {
  public async login (credentials: ApiCredentials): Promise<AxiosResponse<SecurityContext>> {
    if (localStorage.getItem('remember_2fa_token')) {
      credentials.remember_2fa_token = localStorage.getItem('remember_2fa_token')
    }
    const httpParams: IHttpClientPostParameters = {
      url: 'auth/session',
      requireToken: false,
      payload: credentials
    }
    const response = await Container.get(HttpClient).post<SecurityContext>(
      httpParams
    )
    return response
  }

  public async refreshToken (ttl: number): Promise<AxiosResponse<SecurityContext>> {
    const httpParams: IHttpClientPostParameters = {
      url: '/auth/refresh-token',
      requireToken: true
    }
    return await Container.get(HttpClient).post<SecurityContext>(httpParams)
  }

  public async register (register: Register): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: 'auth/user',
      requireToken: false,
      payload: register
    }
    const response = await Container.get(HttpClient).post<SecurityContext>(
      httpParams
    )
    return response
  }

  public async logout (): Promise<any> {
    const httpParams: IHttpClientDeleteParameters = {
      url: '/auth/session',
      requireToken: true
    }
    const response = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async resetPassword (reset: ResetPassword): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: '/auth/password/token',
      payload: reset,
      requireToken: false
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async changePassword (changePassword: ChangePassword): Promise<any> {
    const httpParams: IHttpClientPatchParameters = {
      url: '/auth/password',
      requireToken: false,
      payload: changePassword
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async activateAccount (token: string): Promise<any> {
    const httpParams: IHttpClientPatchParameters = {
      url: `/auth/user/activation/${token}`,
      requireToken: false
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async usePasswordToken (token: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/auth/password/token/${token}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async stopImpersonating () {
    const httpParams: IHttpClientDeleteParameters = {
      url: '/impersonate/user',
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async impersonateUser (user_uuid: string) {
    const httpParams: IHttpClientPostParameters = {
      url: `/impersonate/user/${user_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getCurrentUser () {
    const httpParams: IHttpClientGetParameters = {
      url: '/user/me',
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getPaymentMethods () {
    const httpParams: IHttpClientGetParameters = {
      url: '/user/me/payments/payment-methods',
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async deleteAccess (user_uuid: string, event_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/users/${user_uuid}/events/${event_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async addAccess (user_uuid: any, event_uuid: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/users/${user_uuid}/events/${event_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getUser (user_uuid: any) {
    const httpParams: IHttpClientGetParameters = {
      url: `/user/${user_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async updateCurrentUser (payload: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: '/user/me',
      requireToken: true,
      payload: payload
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async updateUser (user_uuid: any, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/user/${user_uuid}`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async get2faCode (data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: '/auth/user/twofactor',
      requireToken: false,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async send2FAToken (two_factor_purpose: string): Promise<AxiosResponse<any, any>> {
    const httpParams: IHttpClientPostParameters = {
      url: '/auth/user/me/twofactor',
      requireToken: true,
      payload: { two_factor_purpose }
    }
    return await Container.get(HttpClient).post(httpParams)
  }

  public async verify2FAToken (two_factor_token: string): Promise<AxiosResponse<any, any>> {
    const httpParams: IHttpClientPostParameters = {
      url: '/auth/user/me/twofactor/verify',
      requireToken: true,
      payload: { two_factor_token }
    }
    return await Container.get(HttpClient).post(httpParams)
  }

  public async addEventAccess (event_uuid: any, user_uuid: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/users/${user_uuid}/events/${event_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async addEventAccessBulk (user_uuid: any, add_events: string[]) {
    const httpParams: IHttpClientPostParameters = {
      url: `/users/${user_uuid}/events`,
      requireToken: true,
      payload: {
        add_events
      }
    }
    return await Container.get(HttpClient).post(httpParams)
  }

  public async removeEventAccess (event_uuid: any, user_uuid: any) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/users/${user_uuid}/events/${event_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async removeEventAccessBulk (user_uuid: any, remove_events: string[]) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/users/${user_uuid}/events`,
      requireToken: true,
      config: {
        data: { remove_events }
      }
    }
    return await Container.get(HttpClient).delete(httpParams)
  }

  public async getSystemAlert () {
    const httpParams: IHttpClientGetParameters = {
      url: '/systemalerts',
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async updateZapierKey (payload: {
    uuid: string;
    expiration: any;
  }) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/api-keys/${payload.uuid}`,
      requireToken: true,
      payload
    }
    return Container.get(HttpClient).patch(httpParams)
  }

  public async generateZapierKey (payload: {
    name: string;
  }) {
    const httpParams: IHttpClientPostParameters = {
      url: '/api-keys',
      requireToken: true,
      payload: payload
    }

    return Container.get(HttpClient).post(httpParams)
  }

  public async getZapierKeys () {
    const httpParams: IHttpClientGetParameters = {
      url: '/api-keys',
      requireToken: true
    }

    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }
}
