const translateError = (error: any) => {
  if (error.status === 401 && error.config && error.config.url !== 'auth/session') {
    return null
  }
  if (error.status === 404) {
    return null
  }
  if (error.data?.errors) {
    const errors = error.data.errors
    if (Array.isArray(errors)) {
      return errors.join('<br>')
    } else if (typeof errors === 'object') {
      let message = ''
      for (const key in errors) {
        message += `${key}: ${errors[key]}<br>`
      }
      return message
    }
  } else if (error.data && error.data.error_message) {
    return error.data.error_message
  } else if (error.data && error.data.message) {
    return error.data.message
  }
  return 'There was an unexpected error. Please contact support.'
}
export default translateError
