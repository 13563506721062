import { Component, Prop, Vue } from 'vue-property-decorator'
import { UserInstructionSet } from '@/interfaces/common.interface'

@Component({
  name: 'GtrUserInstructions'
})
export default class GtrUserInstructions extends Vue {
  @Prop({ required: true })
  instructionSets!: UserInstructionSet[];

  @Prop({ type: String, default: '' })
  classString = '';

  @Prop({ type: String, default: '' })
  styleString = '';

  data () {
    return {
      show: false,
      instructionSetIndex: 0,
      pageIndex: 0,
      instructionModalOpen: false
    }
  }

  get currentInstructionSet (): UserInstructionSet {
    return this.instructionSets[this.$data.instructionSetIndex]
  }

  get modalTitle (): string {
    return this.currentInstructionSet.topic ?? ''
  }

  get currentPageHeading (): string {
    return this.currentInstructionSet.pages[this.$data.pageIndex]?.heading ?? ''
  }

  get currentPageDescription (): string {
    return this.currentInstructionSet.pages[this.$data.pageIndex]?.description ?? ''
  }

  get currentPageImage (): string {
    return this.currentInstructionSet.pages[this.$data.pageIndex]?.image ?? ''
  }

  get totalPages () {
    return this.currentInstructionSet.pages?.length ?? 0
  }

  get hasPrevious () {
    return this.$data.pageIndex > 0
  }

  get hasNext () {
    return this.$data.pageIndex < this.totalPages - 1
  }

  previous () {
    if (!this.hasPrevious) return
    this.$data.pageIndex--
  }

  next () {
    if (!this.hasNext) return
    this.$data.pageIndex++
  }

  handleShowInstructionSet (index = 0) {
    if (!isNaN(index) && index >= 0) {
      this.$data.instructionSetIndex = index
      this.$data.instructionModalOpen = true
    }
  }

  handleCloseInstructionModal () {
    this.$data.instructionModalOpen = false
    this.$data.pageIndex = 0
    this.$emit('close')
  }
}
