import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'MultiFactorRequiredMixin',
  computed: {
    ...mapState('security', ['currentUser'])
  }
})
export default class MultiFactorRequiredMixin extends Vue {
  currentUser!: Record<string, any>

  @Prop({ required: false, type: Boolean, default: true })
  validToken!: boolean|undefined

  @Prop({ required: false, default: '' })
  token!: string

  @Prop({ required: false, default: true })
  skipToken!: boolean

  @Prop({ required: false, default: false })
  verifying!: boolean

  get mfaToken () {
    return this.token
  }

  set mfaToken (value: string) {
    this.$emit('update:token', value)
  }

  onResendToken () {
    this.$emit('resend')
  }

  onVerifyToken () {
    this.$emit('verify')
  }
}
